import { createStore } from 'vuex'

export default createStore({
  state: {
    categorie:{},
    magasin:null,
    openmagasin:null,
    openlegend:false
  },
  getters: {
    categorie(state){
      return state.categorie;
    },

    magasin(state){
      return state.magasin;
    },


    openmagasin(state){
      return state.openmagasin;
    },

    openlegend(state){
      return state.openlegend;
    }

  },
  mutations: {
    categorie(state,categorie){
      state.categorie=categorie;
    },

    magasin(state,magasin){
      state.magasin=magasin;
    },
    openmagasin(state,openmagasin){
      state.openmagasin=openmagasin;
    },
    openlegend(state,openlegend){
      state.openlegend=openlegend;
    }
  },
  actions: {
  },
  modules: {
  }
})
