
import {defineComponent} from 'vue';
import ClockComponent from "@/components/ClockComponent.vue";
import router from "@/router";

export default defineComponent({
  name: 'HomeView',
  components: {
    ClockComponent
  },
  data() {
    return {
      counter: 0,
      position: 0,
    }
  },
  mounted() {

    if (this.$route.params.id) {
     // console.log(this.$route.params.id);
      localStorage.position = this.$route.params.id;
      router.push({name: 'home'})
    }

    if (localStorage.position) {
      this.position = localStorage.position;
    }
  },
  methods: {

    configSave() {
      this.counter = 0;
      localStorage.position = this.position;
    },
    enter() {
      router.push({name: 'map'})
    }
  }

});
